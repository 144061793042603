
.post {
  &__image {
    width: 100%;
  }
}

header .blog__header-image {
  color: #fff;
  flex: 1;
  justify-content: flex-end;
  text-shadow: 1px 1px 2px #000, 3px 3px 10px rgba(0, 0, 0, 0.5);
  background: linear-gradient(transparent, transparent, rgba(0, 0, 0, 0.75));
  padding-top: 5rem;
  padding-bottom: 2rem;
}
