.navbar {
  text-transform: uppercase;
  letter-spacing: 0.1em;
  font-family: $font-family-accent;

  .nav-item {
    margin-right: 2rem;

    &:last-child {
      margin-right: 0;
    }
  }

  .active > .nav-link {
    font-weight: bold;
  }
}
.navbar-brand {
  img {
    filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.75));
    width: 210px;
  }
}

@include media-breakpoint-up(sm) {
  .navbar .nav-link {
    text-shadow: 1px 1px 2px rgba($black, 0.75);
  }
}
