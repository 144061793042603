select {
  border-radius: 0;
  width: 100%;
}

textarea {
  min-height: 100px;
}

.form-group label {
  text-transform: uppercase;
  font-size: 0.85rem;
  color: $gray-light;
  margin-bottom: 0.25rem;
}

.label-desc {
  display: block;
  color: $gray-lighter;
  text-transform: none;
  line-height: 1.25;
  margin-top: 0.25rem;
  margin-bottom: 0.5rem;
}

form {
  h3, h4 {
    &:not(:first-child) {
      margin-top: 3rem;
    }
  }

  button:last-child {
    margin-top: 3rem;
  }
}
