html {
  -webkit-font-smoothing: antialiased;
}

// Headings

h2 {
  color: $gray-dark;
  margin-bottom: ($spacer * 2);
}

* + h2 {
  margin-top: 3rem;
}

h3 {
  letter-spacing: 0.1em;
  text-transform: uppercase;
  margin-bottom: 1rem;
  color: $gray-dark;
}

* + h3 {
  margin-top: 2rem;
}

// Body

p:last-child {
  margin-bottom: 0;
}

// Misc

dt {
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  color: $gray-dark;
}

.select-all {
  user-select: all;
}
