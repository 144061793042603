.modal-title {
  text-transform: uppercase;
}

.discount-container {
	width:250px;
	margin:auto;
}

.discount {
	margin-top:20px;
}