.event {
  &__title {
    font-size: 3rem;
    margin-bottom: ($spacer * $lg);
  }
  &__subtitle {
    margin-top: 1rem;
    font-size: 50%;
    font-weight: $font-weight-light;
    display: block;
    color: $gray-light;
  }

  &__map {
    margin-top: 1rem;
    min-height: 300px;
    width: 100vw;
    position: relative;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
  }

  .btn small {
    font-weight: $font-weight-light;
  }
}

@include media-breakpoint-down(sm) {
  .event__purchase-box {
    margin-top: ($spacer * $lg);
  }
}
