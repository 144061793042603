#mc-embedded-subscribe {
	min-width: 100%;
  margin-top:1rem;
}

.btn, button {
  border-radius: 0;
}

.btn {
  font-family: $font-family-accent;
  font-weight: $font-weight-light;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  white-space: initial;

  &-primary {
    &:focus {
      color: #fff;
    }
  }
  
  &-event {
  	min-width: 17rem;
  	margin: 0.4rem;
  }

  &__title {
    display: block;
    margin-bottom: ($spacer * $sm);
	  letter-spacing: 0.025em;
  }

  &__desc {
    display: block;
    text-transform: none;
    letter-spacing: 0.025em;
    opacity: 0.7;
  }
}

.event-buttons {
  justify-content: center;
  .btn {
    margin: ($spacer * $sm);
  }

  &__index {
    text-align: center;
    margin-top: ($spacer * $sm * -1);
    margin-bottom: ($spacer * $xl - ($spacer * $sm));
  }
}

.download-buttons {
  justify-content: center;
  .btn {
    margin: ($spacer * $sm);
    min-width: 12rem;
  }
  &__index {
    text-align: center;
    margin-top: ($spacer * $sm);
    margin-bottom: ($spacer * $sm);
  }
}

a, .btn, button {
  &:hover {
    cursor: pointer;
  }
}
