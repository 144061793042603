#footer {
  text-transform: uppercase;
  letter-spacing: 0.1em;
  text-align: center;
  font-size: $font-size-xs;
  background-color: $gray-dark;
  padding: ($spacer * $lg) 0;
  color: $white;

  a {
    color: inherit;
  }

  span {
    display: inline-block;
    padding: 0.125em 0.25em;
  }

  p {
    &:first-of-type {
      font-weight: $font-weight-light;
      color: $gray-lighter;

      span {
        white-space: pre;
      }
    }

    &:last-of-type {
      span {
        display: block;
      }
    }
  }
}
