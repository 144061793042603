#header {
  min-height: 25rem;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  box-shadow: inset 0 0 500px 500px rgba(0,0,0,0.5);
  display: flex;
  flex-direction: column;
}

#trainer {
  min-height: 30rem;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
}

@include media-breakpoint-down(xs) {
  #header {
    min-height: 15rem;
  }
}
