.img-header {
  width: 100%;
  height: 200px;
  display: flex;
  background-size: cover;
  align-items: flex-end;
  justify-content: center;
  margin-bottom: ($spacer * 2);

  & > * {
    background: rgba(0,0,0,0.6);
    width: 100%;
    text-align: center;
    margin-bottom: 0;
    padding: $spacer;
  }

  a {
    color: #fff;
  }

  &__training {
    background-image: url(../images/training.jpg);
  }

  &__services {
    background-image: url(../images/services.jpg);
  }
}
