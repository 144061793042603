p.subtitle {
  font-size: 1.5rem;
  line-height: 1.2;
  opacity: 0.8;
  margin-bottom: 1em;
}

.trainings__sidebar {
  margin-bottom: ($spacer * $xl);
  text-align: center;
}

@include media-breakpoint-up(lg) {
  .trainings__sidebar {
    order: 2;
  }

  .event-buttons {
    &__trainings {
      .btn {
        display: block;
        margin-bottom: ($spacer * $md);
      }
    }
  }
}
