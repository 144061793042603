// Main Layout

body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  overflow-x: hidden;
}

#main {
  padding-top: ($spacer * $xl);
  padding-bottom: ($spacer * $xl);
}

// Add spacing between columns when they wrap to new rows

@include media-breakpoint-down(sm) {
  main > .row > div:nth-of-type(n+2):not([class*="col-sm"]) {
    margin-top: ($spacer * $lg);
  }
}

@include media-breakpoint-down(xs) {
  main > .row > div:nth-of-type(n+2):not([class*="col-xs"]) {
    margin-top: ($spacer * $lg);
  }
}
